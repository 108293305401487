<template>
  <b-card class="p-1">
    <vue-element-loading
      style="min-height: 500px;"
      :active="respostaApi"
      text="Carregando Parametros"
    >
      <img
        src="@/assets/images/logo/logo-loading.gif"
        width="40px"
      >
    </vue-element-loading>


    <div class="m-2">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>Exibir</label>
          <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
            :clearable="false" class="per-page-selector d-inline-block mx-50" @input="alterarPorPagina"/>
        </b-col>
      </b-row>
    </div>

    <b-table ref="parametros" class="position-relative" :items="parametros" responsive :fields="tableColumns"
        primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="Sem resultados" :sort-desc.sync="isSortDirDesc">
      
        <template #cell(id)="data">
          <b-media vertical-align="center">
            <b-link :to="{ name: '', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              #{{ data.item.id }}
            </b-link>
          </b-media>
        </template>

        <template #cell(nome)="data">
          {{ data.item.nome }}
        </template>
        
        <template #cell(descricao)="data">
          {{ data.item.descricao }}
        </template>

        <template #cell(valor)="data">
          {{ data.item.valor }}
        </template>
        
        <template #cell(situacao)="data">
          <div>
            <b-form-checkbox 
              class="custom-control-primary" 
              name="check-button" 
              :checked="isChecked(data.item.situacao)" 
              @change="() => alterarStatus(data.item)"
              switch
            >
            </b-form-checkbox>
          </div>
        </template>


        <template #cell(remover)="data">
          <b-button variant="link" size="sm" class="float-right" @click="abrirModalEditarParemetro(data.item)">
            <feather-icon icon="EditIcon" size="18" class="text-warning" />
          </b-button>
        </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>
      <b-col  cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start"/>
      <b-col  cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
        <b-pagination v-model="currentPage" :total-rows="totalLista" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item" next-class="next-item" @input="mudarPagina">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18"/>
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18"/>
          </template>
        </b-pagination>
       </b-col>
      </b-row>
    </div>
    
      <!-- Modal Edit Parametros -->
      <b-modal id="modal-parametro" ref="modalParametro" hide-footer centered no-close-on-backdrop size="md" title="Configuração de Parametro">
        <validation-observer ref="registerForm" #default="{ invalid }">
          <b-form @submit.prevent="alterarDados">
            <b-form-group label="Descrição" label-for="descricao">
              <validation-provider #default="{ errors }" name="descricao">
                <b-form-input id="descricao" v-model="parametroSelecionado.descricao" :state="errors.length > 0 ? false:null"
                  placeholder="Digite a descrição"/>
                </validation-provider>
              </b-form-group>

            <b-form-group label="Valores" label-for="valor">
              <validation-provider #default="{ errors }" name="descricao">
                <b-form-input id="descricao" v-model="parametroSelecionado.valor" :state="errors.length > 0 ? false:null"
                  placeholder="Digite o valor"/>
                </validation-provider>
              </b-form-group>

              <div class="d-flex justify-content-end">
                <b-form-group label="Status" label-for="situacao">
                  <b-form-checkbox 
                  class="custom-control-primary" 
                  name="check-button" 
                  :checked="isChecked(parametroSelecionado.situacao)" 
                  v-model="parametroSelecionado.situacao"
                  switch
                  >
                </b-form-checkbox>
              </b-form-group>
            </div>
            <b-button variant="primary" class="w-100 mt-2" type="submit" :disabled="invalid || carregamentoAPI">
              Salvar
              <b-spinner v-if="carregamentoAPI" small label="Carregando" />
            </b-button>
          </b-form>
        </validation-observer>
      </b-modal>
  </b-card>

</template>

<script>

import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BButton, BSpinner, BForm, BFormGroup, 
  BFormInput, BRow, BCol, BCard, 
  BInputGroup, BInputGroupAppend, BFormInvalidFeedback,
  BTable, BMedia, BFormCheckbox, BPagination
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import useJwt from '@/auth/jwt/useJwt'

import VueElementLoading from 'vue-element-loading'

import vSelect from 'vue-select'

export default {
  components: {
    BButton,
    BSpinner,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BTable,
    BMedia,
    BFormCheckbox,
    BPagination,
    BInputGroupAppend,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    VueElementLoading,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      parametros: [],
      parametroSelecionado: null,
      totalLista: 0,
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true},
        { key: 'nome', label: 'Nome', sortable: true},
        { key: 'descricao', label: 'Descrição', sortable: true},
        { key: 'valor', label: 'Valor', sortable: false},
        { key: 'situacao', label: 'Status', sortable: false},
        { key: 'remover', label: '' },
      ],
      perPage: 10,
      currentPage: 1,
      totalLista: 0,
      perPageOptions: [10, 25, 50, 100],
      sortBy: 'id',
      isSortDirDesc: true,
      respostaApi: false,
      carregamentoAPI: false,
    }
  },
  mounted() {
    this.listarParametros()
  },
  methods: {
    isChecked(situacao) {
      return situacao;
    },
    abrirModalEditarParemetro(item) {
      this.parametroSelecionado = { ...item };
      this.$refs.modalParametro.show()
    },
    listarParametros() {
      this.respostaApi = true
      const paginaAtual = this.currentPage - 1
      useJwt.listarParametros({
            page: paginaAtual,
            size: this.perPage,
            sort: 'DESC',
          })
            .then(response => {
              this.parametros = response.data.content.map(param => ({
                ...param,
                situacao: param.situacao === 'ATIVO',
              }));
              this.totalLista = response.data.totalElements
              this.respostaApi = false;
            })
            .catch(error => {
              this.$swal({
                title: 'Erro!',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              });
              this.respostaApi = false;
            });
    },
    alterarDados(){
      this.carregamentoAPI = true

      const id = this.parametroSelecionado.id
      const dto = {
        descricao: this.parametroSelecionado.descricao,
        valor: this.parametroSelecionado.valor,
        situacao: this.parametroSelecionado.situacao ? 1 : 0,
      }

      useJwt.alterarDadosParametros({
        id: id,
        dto: dto
      }).then(response => { 
            this.$swal({
                    title: 'Parametro atualizado!',
                    text: 'O parametro atualizado com sucesso.',
                    icon: 'success',
                    customClass: {
                    confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
          })
          }).catch(error => {
                  this.$swal({
                      title: 'Erro!',
                      text: error.response.data.message,
                      icon: 'error',
                      customClass: {
                      confirmButton: 'btn btn-primary',
                      },
                      buttonsStyling: false,
            })
            })
            .finally(() => {
              this.carregamentoAPI = false
              this.$refs.modalParametro.hide()
              this.listarParametros()
            })
    },
    alterarStatus(parametro) {
    const novoStatus = parametro.situacao ? 'INATIVO' : 'ATIVO';
    
    useJwt.alterarSituacaoParametros({ id: parametro.id })
      .then(() => {
        parametro.situacao = !parametro.situacao

        this.$swal({
          title: 'Situação alterada!',
          text: 'A situação do parâmetro foi alterada.',
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
      })
      .catch(error => {
        this.$swal({
          title: 'Erro!',
          text: error.response.data.message,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
      });
   },
    mudarPagina() {
      this.carregarLista()
    },
    alterarPorPagina() {
      this.carregarLista()
    },
    situacaoBoolean(situacao) {
      if (situacao == 'INATIVO') return '0'
      if (situacao == 'ATIVO') return '1'
      return 'Erro'
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>